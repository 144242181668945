<template>
  <div>
    <h2 v-html="$t('search_production_label_title')"></h2>
    <!-- Label -->
    <v-select
      id="search_production_label_type"
      required
      v-model="searchCardStore.labelTypeId"
      :label="$t('search_production_label_type')"
      :items="labelTypes"
      item-value="id"
      :item-title="this.$getLangKey()"
      clearable
      @keyup.enter="search"
    />
    <!-- Complied -->
    <v-select
      id="search_production_label_complied"
      v-model="searchCardStore.complied"
      :label="$t('search_production_label_complied')"
      :items="compliedOptions"
      item-value="value"
      item-title="text"
      clearable
      @keyup.enter="search"
    />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { app } from '@/main'
import { useLabelTypesStore } from '@/store/enums/LabelTypesStore'
import { useSearchProductionStore } from '@/store/SearchProductionStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchProductionLabel',
  extends: baseSearchVue,
  components: {},
  computed: {
    labelTypes() {
      return useLabelTypesStore().LabelTypes
    }
  },
  data() {
    return {
      expanded: false,
      app: app,
      compliedOptions: [
        {
          value: null,
          id: 0,
          text: this.$t('search_production_label_complied_all')
        },
        {
          value: true,
          id: 1,
          text: this.$t('search_production_label_complied_true')
        },
        {
          value: false,
          id: 2,
          text: this.$t('search_production_label_complied_false')
        }
      ]
    }
  },
  beforeMount() {
    this.setSearchCardStore(useSearchProductionStore(), true)
    useLabelTypesStore().fetchLabelTypes()
  }
})
</script>
